const toLower = require("lodash/toLower");
const camelCase = require("lodash/camelCase");
const flow = require("lodash/flow");

const mapValues = require("lodash/mapValues");
const map = require("lodash/map");

const displayToKey = flow(camelCase, toLower);

const lendingSubBrands = ["BorrowMore", "Restructure", "Future Sub-Brand"];

const BRAND_PALETTES = {
    betterborrow: ["#FF5016", "#282828", "#235CFC"],
    chetwood: ["#00D264", "#252526"],
    chetwoodbank: ["#00C8C8", "#033", "#D14600"],
    livelend: ["#00D264", "#002846"],
    modamortgages: ["#FF5078", "#5A0028"],
    smartsave: ["#ED6970", "#291D1F", "#DCD2DC"],
    wave: ["#C94C00", "#0D444D", "#93cdd6", "#DAEEF1"]
};

const BRANDS = {
    betterborrow: "BetterBorrow",
    chetwood: "Chetwood",
    chetwoodbank: "ChetwoodBank",
    livelend: "LiveLend",
    modamortgages: "ModaMortgages",
    smartsave: "SmartSave",
    wave: "Wave"
};

const LENDING_BRANDS = [BRANDS.betterborrow, BRANDS.livelend];

const brandsInfo = mapValues(BRANDS, (brandName) => {
    const brandKey = displayToKey(brandName);
    return {
        key: brandKey,
        displayName: brandName,
        palette: {
            primary: BRAND_PALETTES[brandKey][0],
            secondary: BRAND_PALETTES[brandKey][1]
        }
    };
});

// Specify idiosyncratic logo image types
brandsInfo.chetwood.logoType = "jpg";
brandsInfo.wave.logoType = "svg";

// Add in sub-brands to lending brands
LENDING_BRANDS.forEach((brandName) => {
    const brandKey = displayToKey(brandName);
    brandsInfo[brandKey].subBrands = map(lendingSubBrands, (subBrandName) => ({
        key: displayToKey(subBrandName),
        displayName: subBrandName
    }));
});

const brandConfig = {
    brands: brandsInfo,
    products: {
        lending: [BRANDS.betterborrow, BRANDS.livelend].map(displayToKey)
    }
};

module.exports.brandConfig = brandConfig;
