import React from "react";
import { graphql, Link } from "gatsby";

import values from "lodash/values";

import Logo from "../../../static/chetwood/images/logo.jpg";

import PageHeading from "../../components/PageHeading";
import SubHeading from "../../components/SubHeading";
import Section from "../../components/Section";

import Layout from "../../components/Layout";

const { brandConfig } = require("../../constants/brandConfig");

const createTemplatesLink = (brandKey) => `${brandKey}/templates`;

const IndexPage = () => {
    const orgColor = "#252525";
    const orgBrand = { palette: { primary: orgColor }, key: "chetwood", logoType: "jpg" };
    const brands = values(brandConfig.brands).filter(
        ({ key: brandKey }) => brandKey !== "chetwood"
    );

    return (
        <Layout brandColor={orgColor}>
            <PageHeading background={orgColor} brandInfo={orgBrand} logo={Logo}>
                Chetwood Comms Library
            </PageHeading>

            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4 md:gap-8">
                    <div className="md:col-start-2 md:col-span-4">
                        <Section highlight={true}>
                            <SubHeading>🏦 Organisation</SubHeading>
                            <ul className="mt-3 mb-2 pl-4">
                                <li className="list-disc ml-2 mt-0.5 mb-0.5 text-gray-600">
                                    <Link
                                        to={createTemplatesLink("chetwood")}
                                        className="inline-block underline"
                                    >
                                        Chetwood
                                    </Link>
                                </li>
                            </ul>
                        </Section>
                        <Section highlight={true}>
                            <SubHeading>⚡️ Brands</SubHeading>
                            <ul className="mt-3 mb-2 pl-4">
                                {brands.map((brandInfo) => (
                                    <li
                                        key={brandInfo.key}
                                        className="list-disc ml-2 mt-0.5 mb-0.5 text-gray-600"
                                    >
                                        <Link
                                            to={createTemplatesLink(brandInfo.key)}
                                            className="inline-block underline"
                                        >
                                            {brandInfo.displayName}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Section>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query MyQuery {
        chetwoodMJML: allMjml(
            filter: { sourceInstanceName: { eq: "chetwoodMJML" } }
            sort: { order: ASC, fields: name }
        ) {
            edges {
                node {
                    id
                    name
                    frontmatter {
                        description
                        order
                        tags
                        title
                        deployedBy
                        deployedOn
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        betterborrowMJML: allMjml(
            filter: { sourceInstanceName: { eq: "betterborrowMJML" } }
            sort: { order: ASC, fields: name }
        ) {
            edges {
                node {
                    id
                    name
                    frontmatter {
                        description
                        order
                        tags
                        title
                        deployedBy
                        deployedOn
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        livelendMJML: allMjml(
            filter: { sourceInstanceName: { eq: "livelendMJML" } }
            sort: { order: ASC, fields: name }
        ) {
            edges {
                node {
                    id
                    name
                    frontmatter {
                        description
                        order
                        tags
                        title
                        deployedBy
                        deployedOn
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        smartsaveMJML: allMjml(
            filter: { sourceInstanceName: { eq: "smartsaveMJML" } }
            sort: { order: ASC, fields: name }
        ) {
            edges {
                node {
                    id
                    name
                    frontmatter {
                        description
                        order
                        tags
                        title
                        deployedBy
                        deployedOn
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
